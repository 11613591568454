<br>
<div id='container'>
       

<div class="property-panel-content">
    <table class="e-filter-table">
        <tr>
            <td>
                <div>
                    <ejs-textbox id="search_text" #search_text placeholder="Enter search text" [showClearButton]="true" (focus)="onFocus()" (keyup)="searchClick($event)"></ejs-textbox>
                    <button ejs-button id='addNew' class="e-dialog-add" (click)="addTaskClick()">Add New
                        Task</button>
                </div>
            </td>
        </tr>
        <tr>
            <td>
                <div>
                    <ejs-dropdownlist id='ddlDealsFilter' [fields]='ddlFields' [dataSource]='ddlDataDeals' placeholder='Select a deal' popupHeight='200px' popupWidth='250px' ></ejs-dropdownlist>
                </div>
            </td>
        </tr>
    </table>
</div>
<br>
<ejs-kanban #kanbanObj keyField='status' [dataSource]='taskData' [cardSettings]='cardSettings'
  [swimlaneSettings]='swimlaneSettings'
  (actionComplete)='OnDataChange($event)' 
  [dialogSettings]='dialogSettings'
  [sortSettings]='sortSettings'>
    <e-columns>
        <e-column headerText='To Do' keyField='Open'></e-column>
        <e-column headerText='In Progress' keyField='InProgress'></e-column>
        <e-column headerText='Done' keyField='Close'></e-column>
    </e-columns>
    <ng-template #cardSettingsTemplate let-data>
      <div class='e-card-content'>
          <table class="card-template-wrap" width=100%>
              <tbody>
                <tr>
                    <td class="CardHeader">Priority:</td>
                    <td>{{data.priority}}</td>
                </tr>
                <tr>
                    <td class="CardHeader">Deal:</td>
                    <td>{{data.deal_text}}</td>
                </tr>
                  <tr>
                      <td class="CardHeader">Full Name:</td>
                      <td>{{data.first_name}} {{data.last_name}}</td>
                  </tr>
                  <tr>
                      <td class="CardHeader">Company:</td>
                      <td>{{data.company}}</td>
                  </tr>
                  <tr>
                    <td class="CardHeader">Start Date:</td>
                    <td>{{data.startDate}}</td>
                  </tr>
                  <tr>
                    <td class="CardHeader">Due Date:</td>
                    <td>{{data.dueDate}}</td>
                  </tr>
                  <tr>
                      <td class="CardHeader">Summary:</td>
                      <td>{{data.description}}</td>
                  </tr>
              </tbody>
          </table>
      </div>
  </ng-template>

  <ng-template #dialogSettingsTemplate let-data>
    <table>
        <tbody>
            <tr>
                <td class="e-label">Description</td>
                <td>
                   <div class="e-float-input e-control-wrapper">
                        <textarea type="text" name="description" id="description" class="e-field"
                        value='{{data.description}}'></textarea>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="e-label">Dates:</td>
                <td>
                   <div class="e-float-input e-control-wrapper">
                        Start:<ejs-datetimepicker #taskStartDate name="startDate" id="startDate" [value]='data.startDate' (change)="OnStartDateChange($event, data.id)" format="dd-MMM-yy hh:mm aa"></ejs-datetimepicker>
                        Due:<ejs-datetimepicker #taskDueDate name="dueDate" id="dueDate" [value]='data.dueDate' (change)="OnDueDateChange($event, data.id)" format="dd-MMM-yy hh:mm aa"></ejs-datetimepicker>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="e-label">Deal:</td>
                <td>
                    <div>
                        <ejs-dropdownlist #ddlDeals name="ddlDeals" (change)="onSelected(ddlDeals.value)" id="ddlDeals" [fields]='ddlFields' [dataSource]='ddlDataDeals' placeholder='Select a deal' popupHeight='200px' popupWidth='250px' ></ejs-dropdownlist>
                        
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
  </ng-template>

</ejs-kanban>
</div>