import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { DayService, WeekService, WorkWeekService, MonthService, AgendaService, EventSettingsModel, View, ActionEventArgs, ScheduleModule, DragAndDropService, ResizeService, Schedule, CellClickEventArgs, ResourceDetails } from '@syncfusion/ej2-angular-schedule';
import { EventService} from '../event.service';
import { DataStore} from 'aws-amplify';
import { Contact, MeetingQueue, QueueStatus, Task } from 'src/models';
import { formatDate } from '@angular/common';
import { Subscription } from 'rxjs';
import { DragAndDropEventArgs, TreeViewComponent } from '@syncfusion/ej2-angular-navigations';
import { extend, closest, remove, addClass } from '@syncfusion/ej2-base';
import { Console } from 'console';

@Component({
  selector: 'app-scheduling',
  providers: [DragAndDropService, ResizeService, DayService, WeekService, WorkWeekService, MonthService, AgendaService],
  templateUrl: './scheduling.component.html',
  styleUrls: ['./scheduling.component.scss']
})
export class SchedulingComponent implements OnInit {
  @ViewChild('scheduleObj') scheduleObj: Schedule;
  @ViewChild('toScheduleMeetingList') public toScheduleMeetingList: TreeViewComponent;

  /*****
   * Place holders for tree obj ToSchedule List
   * 
   */
  public isTreeItemDropped = false;
  public draggedItemId = "";
  public draggedItemType = "";
  public draggedItemContact_id = "";

//Get the list of calls to be scheduled/attempted:

public meetingDataAWS: any[];
public meetingData: any[] = [''];


async GetMeetings() {
  this.meetingDataAWS = await DataStore.query(MeetingQueue, t => t.team_id("eq", this.EventService.localTeamID).status("eq", QueueStatus.CREATED));
  console.log("Calling get meetings. Team ID is:" + this.EventService.localTeamID);
  var MeetingIterator: any[] = [];

  this.meetingDataAWS.forEach(async function (value) {
    try {
    var first_name = value["contact"]["first_name"]==null ? "" : value["contact"]["first_name"];
    var last_name = value["contact"]["last_name"]==null ? "" : value["contact"]["last_name"];
    var company = value["contact"]["company"]==null ? "" : value["contact"]["company"];

    var meetingItem = {
      company: <string> company,
      email: <string> value["contact"]["email"],
      id: <string> value["id"],
      first_name: <string> first_name,
      last_name: <string> last_name,
      mobile: <string> value["contact"]["mobile"],
      eng_ranking: <string> value["contact"]["eng_ranking"],
      status: <string> value["status"],
      description: <string> value["description"],
      priority: <number> value["priority"],
      contact_id: <string> value["contact"]["id"],
    };
    
    MeetingIterator.push(meetingItem);
  }
  catch (error: any) {
    console.log(error);
  }
  });
  this.meetingData = [''];

  for(let i=0;i<MeetingIterator.length;i++) {
      this.meetingData[i] = MeetingIterator[i];
  }
 
  this.toScheduleMeetingList.fields = { dataSource: this.meetingData, id: 'id', text: 'first_name' };
}

  public field: Record<string, any>;
  public allowDragAndDrop = true;

  public onTreeDrag(event: any): void {
    if (this.scheduleObj.isAdaptive) {
      const classElement: HTMLElement = this.scheduleObj.element.querySelector('.e-device-hover');
      if (classElement) {
        classElement.classList.remove('e-device-hover');
      }
      if (event.target.classList.contains('e-work-cells')) {
        addClass([event.target], 'e-device-hover');
      }
    }
  }
  
  public onTreeDragStop(event: DragAndDropEventArgs): void {
    const treeElement: Element = closest(event.target, '.e-treeview') as Element;
    const classElement: HTMLElement = this.scheduleObj.element.querySelector('.e-device-hover');
    if (classElement) {
      classElement.classList.remove('e-device-hover');
    }
    if (!treeElement) {
      event.cancel = true;
      const scheduleElement: Element = closest(event.target, '.e-content-wrap') as Element;

      if (scheduleElement) {
        const treeviewData: Record<string, any>[] = this.toScheduleMeetingList.fields.dataSource as Record<string, any>[];
        console.log("Treeview is :");
        console.log(treeviewData);

        if (event.target.classList.contains('e-work-cells')) {
          
          const filteredData: Record<string, any>[] = treeviewData.filter((item: any) => item.id === event.draggedNodeData.id);
          console.log(event.draggedNodeData.id);

          const cellData: CellClickEventArgs = this.scheduleObj.getCellDetails(event.target);
          console.log(filteredData[0]);

          const eventData: Record<string, any> = {
            Name: filteredData[0].first_name,
            description: filteredData[0].description,
            StartTime: cellData.startTime,
            startDate: cellData.startTime,
            EndTime: cellData.endTime,
            dueDate: cellData.endTime,
            IsAllDay: cellData.isAllDay,
            Description: filteredData[0].description,
            meeting_id: filteredData[0].id,
            contact_id: filteredData[0].contact_id
          };


          this.scheduleObj.openEditor(eventData, 'Add', true);
          this.isTreeItemDropped = true;
          this.draggedItemId = event.draggedNodeData.id as string;
          this.draggedItemType = "meeting";
          this.draggedItemContact_id = eventData.contact_id as string;

          
        }
      }
    }
    document.body.classList.remove('e-disable-not-allowed');
  }
  public onTreeDragStart() {
    document.body.classList.add('e-disable-not-allowed');
  }
  public onItemSelecting(args: any): void {
    args.cancel = true;
  }

//end stuff added to test treeview

  public taskDataAWS: any[];
  public taskData: any[];
  /*public taskData: object[] = [{
    Id: 1,
    Subject: 'Loading',
    StartTime: new Date(2018, 1, 15, 10, 0),
    EndTime: new Date(2018, 1, 15, 12, 30),
    IsAllDay: false,
    meeting_id: '1234'
  }];*/

  //public teamID: string;
  public dateFormat: string = "dd/MM/yy";

  public eventSettings: EventSettingsModel;
  public scheduleViews: View[] = ['Day', 'Week', 'Month', 'Agenda'];

  private TaskDataSubscription: Subscription | null = null;

  constructor(private ngZone: NgZone, private EventService:EventService) {
    this.taskData = [];
    this.GetMeetings();
   }

  ngOnInit(): void {
    
    this.EventService.createScreenChangeEvent("schedule");

    
    //Initialise event setting for load:
    this.eventSettings = { 
      dataSource: this.taskData,
       fields: {
         id: 'event_id',
         subject: { name: 'description' },
         isAllDay: { name: 'isAllDay' },
         startTime: { name: 'startDate' },
         endTime: { name: 'dueDate' },
       },
        spannedEventPlacement: 'TimeSlot'
     };
     
    this.TaskDataSubscription = <Subscription>(
      DataStore.observeQuery(Task).subscribe((msg: any) => {
        //console.log('msg Task subscription:', msg);
        this.GetTasks();
      })
    );

    //Initialise the events to be scheduled:
    this.GetMeetings();

    this.ngZone.run(() => {;});
  }

  ngOnDestroy(): void {
    if (this.TaskDataSubscription) {
    this.TaskDataSubscription.unsubscribe();
    }
  
  }
  

  async GetTasks() {
    this.taskDataAWS = await DataStore.query( Task, (t) => t.status("eq", "InProgress").team_id("eq",this.EventService.localTeamID) );

    var taskIterator: any[] = [];
    
    this.taskDataAWS.forEach(async function (value) {
      try {
        if (value["contact"] != null) {
          var first_name = value["contact"]["first_name"]==null ? "" : value["contact"]["first_name"];
          var last_name = value["contact"]["last_name"]==null ? "" : value["contact"]["last_name"];
          var company = value["contact"]["company"]==null ? "" : value["contact"]["company"];   
          var email= value["contact"]["email"]==null ? "" : value["contact"]["email"];
          var mobile= value["contact"]["mobile"]==null ? "" : value["contact"]["mobile"];
          var eng_ranking=value["contact"]["eng_ranking"]==null ? "": value["contact"]["eng_ranking"];
          var contact_id=value["contact"]["id"];
        }
        else { first_name=""; last_name="", company=""; email=""; mobile="";eng_ranking=0;contact_id="";}
      
      var swimlane = company + " " + first_name + " " + last_name;
      let taskDueDateTime = new Date(value["dueDate"]);
      let taskStartDateTime = new Date(value["startDate"]);

      var taskItem = {
        company: <string> company,
        email: <string> email,
        first_name: <string> first_name,
        last_name: <string> last_name,
        swimlane: <string> swimlane,
        mobile: <string> mobile,
        eng_ranking: <string> eng_ranking,
        status: <string> value["status"],
        description: <string> value["description"],
        startDate: <string> formatDate(taskStartDateTime, 'dd-MMM-yy hh:mm aa', "en-us"),
        dueDate: <string> formatDate(taskDueDateTime, 'dd-MMM-yy hh:mm aa', "en-us"),
        event_id: <string> value["id"],
        priority: <number> value["priority"],
        contact_id: <string> contact_id,
        meeting_id: <string> "",
        isAllDay: false
      };

      taskIterator.push(taskItem);

    }
    catch (error: any) {
      //Swallow the promise rejection
      console.log(error);
    }

  });

    for(let i=0;i<taskIterator.length;i++) {
        this.taskData[i] = taskIterator[i];
    }
    try {
     this.scheduleObj.refreshEvents();
    } catch (error:any) {console.log(error);}

}  

onEditButtonClick()
{
  console.log("Editing");
}

onDataChange(state: ActionEventArgs) {

  //console.log(state);
  switch(state.requestType) {
    case 'eventCreated':
        // iterate through array of changed records:
        for(let i=0;i<state.addedRecords.length;i++) {
          //this.saveTaskObject(state.changedRecords[i]);
          console.log("Added:");
          console.log(state.addedRecords[i]);
          //Add the task to reflect the new record:
          this.createTaskObject(state.addedRecords[i]);
        }
      break;
    case 'eventChanged':
        // iterate through array of changed records:
        for(let i=0;i<state.changedRecords.length;i++) {
          this.saveTaskObject(state.changedRecords[i]);
        }
      break;
    case 'eventRemoved':
      // iterate through array of deleted records:
      for(let i=0;i<state.deletedRecords.length;i++) {
        this.deleteTaskObject(state.deletedRecords[i].event_id); 
      }
    break;
    case 'xxxx': break; //Do nothing
    default:
      console.log("New request Type:");
      console.log(state);
  }
  
  }
  
  async deleteTaskObject(id:string) {
    try {
      DataStore.delete(Task, id);
    } catch (error) {
      console.log("Error deleting task", error);
    } 
  }
  async saveTaskObject(taskArray: Record<string, any> | Record<string, any>[])
  {
    try {
      const original = await DataStore.query(Task, (taskArray as any).event_id);
  
      //Prep dates, set the dates to the saved setting then iteratate through the changed dates to see if there was an update:
      var TaskDueDate: string;
      var TaskStartDate: string;
      var d:Date;
      d = new Date((taskArray as any).dueDate);
      TaskDueDate = d.toISOString();
      d = new Date((taskArray as any).startDate);
      TaskStartDate = d.toISOString();

      console.log("Saving:");
      console.log(taskArray);
      console.log(original);
      
      //Now save:
      await DataStore.save(
        Task.copyOf(original, updated => {
          updated.status=(taskArray as any).status,
          updated.description=(taskArray as any).description,
          updated.priority=(taskArray as any).priority,
          updated.dueDate=TaskDueDate,
          updated.startDate=TaskStartDate
        })
      );    
      
      //this.GetTasks();
  
    } catch (error) {
      console.log("Error saving task: ", error);
    } 
  }
  
  //Called when a new event is scheduled:
  async createTaskObject(taskArray: Record<string, any> | Record<string, any>[])
  {
    try {
      //Prep dates, set the dates to the saved setting then iteratate through the changed dates to see if there was an update:
      var TaskDueDate: string;
      var TaskStartDate: string;
      var d:Date;
      d = new Date((taskArray as any).dueDate);
      TaskDueDate = d.toISOString();
      d = new Date((taskArray as any).startDate);
      TaskStartDate = d.toISOString();

      //console.log(original);
      var cognitoSubID:string = await this.EventService.localUserID;
      //var teamID:string = await this.teamID;
    
      //Create task object:
      var newContact: Contact;
      var contactArray: Contact[];
      console.log(this.draggedItemContact_id);
      //alert("fix the contact - need to make a contact search");
      //console.log(taskArray);
      contactArray = await DataStore.query(Contact, c => c.id("eq", this.draggedItemContact_id)); 
      newContact = contactArray[0];

      var newTask: Task;
      newTask =  new Task ({
        contact: newContact,
        create_id: cognitoSubID,
        description: (taskArray as any).description,
        startDate: TaskStartDate, //YYYY-MM-DDThh:mm:ss.sssZ
        dueDate: TaskDueDate, //YYYY-MM-DDThh:mm:ss.sssZ
        team_id: this.EventService.localTeamID,
        status: "InProgress"
      })

      //Now save:
      await DataStore.save(newTask);  

      //Update the dragged queue item if needed:
      if (this.draggedItemId != "" && this.draggedItemType=="meeting") {
        console.log("Updating dragged item");
        console.log(this.draggedItemId);
        const original = await DataStore.query(MeetingQueue, c => c.id("eq", this.draggedItemId));

        await DataStore.save(
          MeetingQueue.copyOf(original[0], updated => {
            updated.status=QueueStatus.SCHEDULED
          })
        );  
        this.draggedItemId = "";
        this.draggedItemType = "";
        this.draggedItemContact_id = "";
        this.GetMeetings();
      }

      
  
    } catch (error) {
      console.log("Error creating task: ", error);
    } 
  }
  
}
