import { DatePipe, formatDate } from '@angular/common';
import { ConditionalExpr } from '@angular/compiler';
import { Component, OnInit, ViewChild, NgZone, ViewEncapsulation, } from '@angular/core';
import { DateTimePicker } from '@syncfusion/ej2-angular-calendars';
import { KanbanComponent, CardSettingsModel, SortSettingsModel, SwimlaneSettingsModel, ColumnsModel, 
  DialogSettingsModel, ActionEventArgs,  
  Kanban} from '@syncfusion/ej2-angular-kanban';
import { DataStore, Hub, SortDirection } from 'aws-amplify';
import { Contact, Deal, Task } from '../../models';
import { EventService} from '../event.service';
import { Query } from '@syncfusion/ej2-data';
import { TextBoxComponent } from '@syncfusion/ej2-angular-inputs';
import { Subscription } from 'rxjs';
import { createSpinner, hideSpinner, showSpinner } from '@syncfusion/ej2-popups';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TasksComponent implements OnInit {
  @ViewChild('kanbanObj') kanbanObj: KanbanComponent;
  @ViewChild('taskDueDate') elementTaskDueDate: DateTimePicker;
  @ViewChild('search_text') textBoxObj: TextBoxComponent;

  public taskDataAWS: any[];
  public taskData: any[];
  public ddlDataDealsAWS: any[];
  public ddlDataDeals: any[];
  

  public ddlFields: Object = { text: 'description', value: 'id' };

  public cardSettings: CardSettingsModel = {
    headerField: "id",
    contentField: "description",
    //showHeader: false
    showHeader: true
  };
  //public swimlaneSettings: SwimlaneSettingsModel = { keyField: 'swimlane' };
  public swimlaneSettings: SwimlaneSettingsModel = { };

  public columns: ColumnsModel[] = [
    { headerText: 'To Do', keyField: 'Open' },
    { headerText: 'In Progress', keyField: 'InProgress' },
    { headerText: 'Done', keyField: 'Close' }
  ];
  
  public dialogSettings: DialogSettingsModel = {
    fields: [
        { text: 'Task Details', key: 'Description', type: 'TextArea'},
        { text: 'Start Date', key: 'startDate', type: 'TextBox' },
        { text: 'Due Date', key: 'dueDate', type: 'TextBox' }
    ]
  };

  public sortSettings: SortSettingsModel  = {
    sortBy: 'Index',
    field: 'priority'
  };

  public teamID: string;
  public tempTaskStartDate:string [] = [];
  public tempTaskIDStartDate:string [] = [];
  
  public tempTaskDueDate: string [] = [];
  public tempTaskIDDueDate: string [] = [];

  public emptyValue: Boolean = true;

  private TaskDataSubscription: Subscription | null = null;

  public ddlDealID: string = '';

  private isRefreshing: boolean = false;

constructor(private ngZone: NgZone, private EventService:EventService) {  
  this.taskData = [];
  this.ddlDataDeals = [];
}

  async ngOnInit(): Promise<void> {
    
    this.EventService.currentTeamID.subscribe(teamID => this.teamID = teamID);
    this.EventService.createScreenChangeEvent("task");
    this.GetDeals();
    
    this.ngZone.run(() => {;});

    this.TaskDataSubscription = <Subscription>(
      DataStore.observeQuery(Task).subscribe(async (msg: any) => {
        console.log('Msg from tasks component Task subscription:', msg);
        //This spot is causing the UI to crash when lots of updates are done...changed to only refresh the control when the DB is not synced.
        if (!msg.isSynced) {await this.GetTasks();} 
      })
    );

    await this.GetTasks();  
    console.log("Loading");
}


ngOnDestroy(): void {
  if (this.TaskDataSubscription) {
  this.TaskDataSubscription.unsubscribe();
  }

}

onSelected(value:any): void{
  if (value!=null) {this.ddlDealID = value;}
  console.log("On selected for deal has been called: " + this.ddlDealID);
}

addTaskClick(): void {
  
  const cardDetails = {
    status: <string> "Open",
    description: "",
    startDate: <string> formatDate(new Date(), 'dd-MMM-yy hh:mm aa', "en-us"),
    dueDate: <string> formatDate(new Date(), 'dd-MMM-yy hh:mm aa', "en-us"),
    priority: <number> -1
  };
  this.kanbanObj.openDialog('Add', cardDetails);
}

  async OnDataChange(state: ActionEventArgs)
{
  switch(state.requestType) {
    case 'cardChanged':
        // iterate through array of changed records:
        console.log("Card Changed");
        for(let i=0;i<state.changedRecords.length;i++) {
          this.isRefreshing = true;
          console.log("Saving record " + i);
          await this.saveTaskObject(state.changedRecords[i]);
          this.isRefreshing = false;
        }
      break;
    case 'cardRemoved':
      // iterate through array of deleted records:
      for(let i=0;i<state.deletedRecords.length;i++) {
        this.deleteTaskObject(state.deletedRecords[i].id);
      }
    break;
    case 'cardCreated':
        // iterate through array of created records:
        for(let i=0;i<state.addedRecords.length;i++) {
          //this.saveTaskObject(state.addedRecords[i]);
          console.log("New Record!");
          console.log(state.addedRecords[i]);
          this.createTaskObject(state.addedRecords[i]);

        }
      break;
    case 'xxxx': break; //Do nothing
    default:
      console.log("New request Type:");
      console.log(state);
  }
  
}

async deleteTaskObject(id:string) {
  try {
    DataStore.delete(Task, id);
  } catch (error) {
    console.log("Error deleting task", error);
  } 
}

async saveTaskObject(taskArray: Record<string, any> | Record<string, any>[])
{

  try {
    const original = await DataStore.query(Task, (taskArray as any).id);
    var task_contact: any = null;

    if (original.contact != null) {task_contact = await DataStore.query(Contact, original.contact.id);}
    
    const task_deal = await DataStore.query(Deal, this.ddlDealID);


    //ToDo: As the Kanban control cascades priority updates and changes the priorities on a move, 
    // the Kanban control is returning an array of the entire column of items below the one being edited.
    // Look for a date to update, iterate through the updated arrays. If I find the ID in 
    // the array use the new date. If I don't find one assume the original date is OK

    
    //Prep dates, set the dates to the saved setting then iteratate through the changed dates to see if there was an update:
    var TaskDueDate: string;
    var TaskStartDate: string;
    var d:Date;
    d = new Date((taskArray as any).dueDate);
    TaskDueDate = d.toISOString();
    d = new Date((taskArray as any).startDate);
    TaskStartDate = d.toISOString();

    for(let i=0; i<this.tempTaskIDDueDate.length;i++) {
        if ((taskArray as any).id == this.tempTaskIDDueDate[i]) {
          const d = new Date(this.tempTaskDueDate[i]);
          TaskDueDate = d.toISOString();
        }
      }  
      
    for(let i=0; i<this.tempTaskIDStartDate.length;i++) {
      if ((taskArray as any).id == this.tempTaskIDStartDate[i]) {
        const d = new Date(this.tempTaskStartDate[i]);
        TaskStartDate = d.toISOString();
      }
    }  
    
    await DataStore.save(
      Task.copyOf(original, updated => {
        updated.status=(taskArray as any).status,
        updated.description=(taskArray as any).description,
        updated.deal=task_deal,
        updated.contact=task_contact,
        updated.priority=(taskArray as any).priority,
        updated.dueDate=TaskDueDate,
        updated.startDate=TaskStartDate
      })
    );    
    
    
    //Reset all the trackers:
    this.tempTaskDueDate = [];
    this.tempTaskIDDueDate = [];
    this.tempTaskStartDate = [];
    this.tempTaskIDStartDate = [];
    this.ddlDealID="";

  } catch (error) {
    console.log("Error saving task: ", error);
  } 

}

OnDueDateChange(args:any, taskID:string) {
  const d = new Date(args.value);
  this.tempTaskDueDate.push(d.toISOString());
  this.tempTaskIDDueDate.push(taskID);
}

OnStartDateChange(args:any, taskID:string) {
  const d = new Date(args.value);
  this.tempTaskStartDate.push(d.toISOString());
  this.tempTaskIDStartDate.push(taskID);
}

async GetTasks() {
  
    this.taskDataAWS = await DataStore.query(Task, t => t.team_id("eq", this.teamID), {sort: t => t.priority(SortDirection.ASCENDING)});

    var taskIterator: any[] = [];

    this.taskDataAWS.forEach(async function (value) {
      try {
      
        var first_name = "";
        var last_name = "";
        var company = "";
        var email = "";
        var mobile = "";
        var eng_ranking = 0;
        var con_id = "";
        var dl_id = "";
        var dl_text = "";


        if (value["contact"]) {
          first_name = value["contact"]["first_name"]==null ? "" : value["contact"]["first_name"];
          last_name = value["contact"]["last_name"]==null ? "" : value["contact"]["last_name"];
          company = value["contact"]["company"]==null ? "" : value["contact"]["company"];  
          email = value["contact"]["email"]==null ? "" : value["contact"]["email"]; 
          mobile = value["contact"]["mobile"]==null ? "" : value["contact"]["mobile"];  
          eng_ranking = value["contact"]["eng_ranking"]==null ? 0 : value["contact"]["eng_ranking"];  
          con_id = value["contact"]["id"]==null ? "" : value["contact"]["id"];  
        }

        if (value["deal"]) {
          dl_id = value["deal"]["id"]==null ? "" : value["deal"]["id"];
          dl_text = value["deal"]["id"]==null ? "" : value["deal"]["description"];
        }
        
      var swimlane = company + " " + first_name + " " + last_name;
      let taskDueDateTime = new Date(value["dueDate"]);
      let taskStartDateTime = new Date(value["startDate"]);

      
      var taskItem = {
        company: <string> company,
        email: <string> email,
        first_name: <string> first_name,
        last_name: <string> last_name,
        swimlane: <string> swimlane,
        mobile: <string> mobile,
        eng_ranking: <string> eng_ranking.toString(),
        status: <string> value["status"],
        description: <string> value["description"],
        startDate: <string> formatDate(taskStartDateTime, 'dd-MMM-yy hh:mm aa', "en-us"),
        dueDate: <string> formatDate(taskDueDateTime, 'dd-MMM-yy hh:mm aa', "en-us"),
        id: <string> value["id"],
        priority: <number> value["priority"],
        contact_id: <string> con_id,
        deal_id: <string> dl_id,
        deal_text: <string> dl_text
      };
      //console.log(taskItem);
      taskIterator.push(taskItem);
  
    }
    catch (error: any) {
      //Swallow the promise rejection
      console.log("Promise rejection");
      //console.log(error);
    }

  });

    for(let i=0;i<taskIterator.length;i++) {
        this.taskData[i] = taskIterator[i];
    }
 try {
     this.ngZone.run(() => {this.kanbanObj.render();});
  }
  catch (error: any) {
    //Swallow the redraw error
    console.log("Tasks tried to redraw when not visible")
    //console.log(error);
    
  }

  
}

async GetDeals() {
  this.ddlDataDealsAWS = await DataStore.query(Deal, d => d.team_id("eq", this.teamID), {sort: d => d.description(SortDirection.DESCENDING)});

  var dealIterator: any[] = [];
  
  this.ddlDataDealsAWS.forEach(async function (value) {
    try {
    
      var description = "";
      var id = "";
      var status="";
      let taskDueDateTime = new Date(value["dueDate"]);
      let taskStartDateTime = new Date(value["startDate"]);

    
    var dealItem = {
      status: <string> value["status"],
      description: <string> value["description"],
      startDate: <string> formatDate(taskStartDateTime, 'dd-MMM-yy hh:mm aa', "en-us"),
      dueDate: <string> formatDate(taskDueDateTime, 'dd-MMM-yy hh:mm aa', "en-us"),
      id: <string> value["id"],
      priority: <number> value["priority"],
    };
    //console.log(taskItem);
    dealIterator.push(dealItem);

  }
  catch (error: any) {
    //Swallow the promise rejection
    console.log(error);
  }

});

  for(let i=0;i<dealIterator.length;i++) {
      this.ddlDataDeals[i] = dealIterator[i];
  }
try {
  this.ngZone.run(() => {this.kanbanObj.render();});
}
catch (error: any) {
  //Swallow the redraw error
  console.log("Deals drop down tried to repopulate when not visible");
  console.log(error);
}

}


//Kanban filter functionality:
searchClick(e: KeyboardEvent): void {
  if (e.code === 'Tab' || e.code === 'Escape' || e.code === 'ShiftLeft' || (e.code === 'Backspace' && this.emptyValue)) {
      return;
  }
  const searchValue: string = (<HTMLInputElement>e.target).value;
  searchValue.length === 0 ? this.emptyValue = true : this.emptyValue = false;
  let searchQuery: Query = new Query();
  if (searchValue !== '') {
      searchQuery = new Query().search(searchValue, ['Id', 'Summary', 'description', 'first_name', 'last_name', 'company'], 'contains', true);
  }
  this.kanbanObj.query = searchQuery;
}

resetClick(): void {
  this.textBoxObj.value = '';
  this.reset();
}

public onFocus = (): void => {
  if (this.textBoxObj.value === null || this.textBoxObj.value === '') {
      this.reset();
  }
}

reset(): void {
  this.kanbanObj.query = new Query();
}

 //Called when a new event is scheduled:
 async createTaskObject(taskArray: Record<string, any> | Record<string, any>[])
 {
   try {
     //Prep dates, set the dates to the saved setting then iteratate through the changed dates to see if there was an update:
     var TaskDueDate: string;
     var TaskStartDate: string;
     var d:Date;
     d = new Date((taskArray as any).dueDate);
     TaskDueDate = d.toISOString();
     d = new Date((taskArray as any).startDate);
     TaskStartDate = d.toISOString();

     //console.log(original);
     var cognitoSubID:string = await this.EventService.localUserID;
     //var teamID:string = await this.teamID;
   
     //Create task object:
     //var newContact: Contact;
     //var contactArray: Contact[];
     
     //alert("fix the contact - need to make a contact search");
     //console.log(taskArray);
     //contactArray = await DataStore.query(Contact, c => c.id("eq", this.draggedItemContact_id)); 
     //newContact = contactArray[0];

     var newTask: Task;
     newTask =  new Task ({
       //contact: newContact,
       create_id: cognitoSubID,
       description: (taskArray as any).description,
       startDate: TaskStartDate, //YYYY-MM-DDThh:mm:ss.sssZ
       dueDate: TaskDueDate, //YYYY-MM-DDThh:mm:ss.sssZ
       team_id: this.EventService.localTeamID,
       status: "Open"
     })

     //Now save:
     await DataStore.save(newTask);  
   } catch (error) {
     console.log("Error creating task: ", error);
   } 
 }


}

