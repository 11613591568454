import { formatDate } from '@angular/common';
import { AfterViewInit, Component, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DateTimePicker } from '@syncfusion/ej2-angular-calendars';
import { ActionEventArgs, CardSettingsModel, ColumnsModel, DialogSettingsModel, KanbanComponent, SortSettingsModel, SwimlaneSettingsModel } from '@syncfusion/ej2-angular-kanban';
import { EventService} from '../event.service';
import { Auth, DataStore, Hub, SortDirection } from 'aws-amplify';
import { DealKanBanColumnSetting } from 'src/models';
import { Deal } from 'src/models';
import { TextBoxComponent } from '@syncfusion/ej2-angular-inputs';
import { Query } from '@syncfusion/ej2-data';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-deals',
  templateUrl: './deals.component.html',
  styleUrls: ['./deals.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DealsComponent implements OnInit {
  @ViewChild('kanbanObj') kanbanObj: KanbanComponent;
  @ViewChild('dealDueDate') elementDealDueDate: DateTimePicker;
  @ViewChild('search_text') textBoxObj: TextBoxComponent;

  public dealDataAWS: any[];
  public columnDataAWS: any[];
  public dealData: any[];
  public cardSettings: CardSettingsModel = {
    headerField: "id",
    contentField: "description",
    showHeader: false
  };
  //public swimlaneSettings: SwimlaneSettingsModel = { keyField: 'swimlane' };
  public swimlaneSettings: SwimlaneSettingsModel = { };
  
  public columns: ColumnsModel[] = [
    { headerText: 'To Do', keyField: 'Open' },
    { headerText: 'In Progress', keyField: 'InProgress' },
    { headerText: 'In Test', keyField: 'InTest' },
    { headerText: 'Done', keyField: 'Close' }
  ];
  
  public dialogSettings: DialogSettingsModel = {
    fields: [
        { text: 'Deal Details', key: 'Description', type: 'TextArea'}, 
        { text: 'Due Date!!', key: 'dueDate', type: 'TextBox' }
    ]
  };

  public sortSettings: SortSettingsModel  = {
    sortBy: 'Index',
    field: 'priority'
  };

  public teamID: string;
  public tempDealDate: string = "";
  public emptyValue: Boolean = true;
  
  private DealDataSubscription: Subscription | null = null;
  private DealColumnsDataSubscription: Subscription | null = null;

  constructor(private ngZone: NgZone, private EventService:EventService) {  
    this.dealData = [];
  }

  ngOnInit(): void {
    this.EventService.currentTeamID.subscribe(teamID => this.teamID = teamID);
    this.EventService.createScreenChangeEvent("deal");
    this.DealDataSubscription = <Subscription>(
      DataStore.observeQuery(Deal).subscribe((msg: any) => {
        console.log('Msg from Deals component Deal subscription:', msg);
        if (!msg.isSynced) {this.GetDeals();}
      })
    );

    this.DealColumnsDataSubscription = <Subscription>(
      DataStore.observeQuery(DealKanBanColumnSetting).subscribe((msg: any) => {
        console.log('Msg from Deals component DealKanban subscription:', msg);
        if (!msg.isSynced) {this.GetDeals();}
      })
    );

    this.GetDeals();
    this.GetColumns();
  }

  
ngOnDestroy(): void {
  if (this.DealDataSubscription) {
  this.DealDataSubscription.unsubscribe();
  }

}

  OnDataChange(state: ActionEventArgs)
  {
    //console.log(state);
    switch(state.requestType) {
      case 'cardChanged':
          // iterate through array of changed records:
          for(let i=0;i<state.changedRecords.length;i++) {
            this.saveDealObject(state.changedRecords[i]);
          }
        break;
      case 'cardRemoved':
        // iterate through array of deleted records:
        for(let i=0;i<state.deletedRecords.length;i++) {
          this.deleteDealObject(state.deletedRecords[i].id);
        }
      break;
      case 'xxxx': break; //Do nothing
      default:
        console.log("New request Type:");
        console.log(state);
    }
    
  }
  
  async deleteDealObject(id:string) {
    try {
      DataStore.delete(Deal, id);
    } catch (error) {
      console.log("Error deleting Deal", error);
    } 
  }
  
  async saveDealObject(dealArray: Record<string, any> | Record<string, any>[])
  {
    try {
      const original = await DataStore.query(Deal, (dealArray as any).id);
      console.log(dealArray);
  
      console.log(this.tempDealDate);
  
      if (this.tempDealDate!="") {
        await DataStore.save(
          Deal.copyOf(original, updated => {
            updated.status=(dealArray as any).status,
            updated.description=(dealArray as any).description,
            updated.priority=(dealArray as any).priority,
            updated.dueDate=this.tempDealDate;
            updated.targetValue=parseInt((dealArray as any).targetValue)
          })
        )
      } else {
        await DataStore.save(
          Deal.copyOf(original, updated => {
            updated.status=(dealArray as any).status,
            updated.description=(dealArray as any).description,
            updated.priority=(dealArray as any).priority,
            updated.targetValue=parseInt((dealArray as any).targetValue)
          })
        )
      }
      this.tempDealDate = "";
      
      this.GetDeals();
    } catch (error) {
      console.log("Error saving Deal: ", error);
    } 
  }
  
  OnDateChange(args:any) {
    const d = new Date(args.value);
    this.tempDealDate = d.toISOString();
    console.log(args);
  }
  
  async GetDeals() {
      this.dealDataAWS = await DataStore.query(Deal, t => t.team_id("eq", this.teamID));
      var dealIterator: any[] = [];
      
  
      this.dealDataAWS.forEach(async function (value) {
        try {
        var first_name = value["contact"]["first_name"]==null ? "" : value["contact"]["first_name"];
        var last_name = value["contact"]["last_name"]==null ? "" : value["contact"]["last_name"];
        var company = value["contact"]["company"]==null ? "" : value["contact"]["company"];
  
        var swimlane = company + " " + first_name + " " + last_name;
        let dealDateTime = new Date(value["dueDate"]);
  
        
        var dealItem = {
          company: <string> company,
          email: <string> value["contact"]["email"],
          first_name: <string> first_name,
          last_name: <string> last_name,
          swimlane: <string> swimlane,
          mobile: <string> value["contact"]["mobile"],
          eng_ranking: <string> value["contact"]["eng_ranking"],
          status: <string> value["status"],
          description: <string> value["description"],
          dueDate: <string> formatDate(dealDateTime, 'dd-MMM-yy hh:mm aa', "en-us"),
          id: <string> value["id"],
          priority: <number> value["priority"],
          contact_id: <string> value["contact"]["id"],
          targetValue: <number> value["targetValue"]
        };
  
        dealIterator.push(dealItem);
    
      }
      catch (error: any) {
        //Swallow the promise rejection
      }
  
    });
  
      for(let i=0;i<dealIterator.length;i++) {
          this.dealData[i] = dealIterator[i];
      }
   
      try {
        this.ngZone.run(() => {this.kanbanObj.render();});
      }
      catch (error: any) {
        //Swallow the redraw error
        console.log("Tasks tried to redraw when not visible")
        console.log(error);
      }
  }
  
  async CognitoSubID():Promise<string> {
    const user = await Auth.currentAuthenticatedUser({bypassCache: true});
    return(user.attributes.sub);
  }

  
  async GetColumns() {
    
    this.columnDataAWS = await DataStore.query(DealKanBanColumnSetting, t => t.team_id("eq", this.teamID), {
      sort: s => s.order(SortDirection.ASCENDING)
    });
    var columnIterator: any[] = [];

    this.columnDataAWS.forEach(async function (value) {
      try {
        var text = value["text"];
        var keyField = value["keyField"];
        var allowToggle = value["allowToggle"];
        
        var DealKanBanColumnSettingItem = {
          headerText: <string> text,
          keyField: <string> keyField,
          allowToggle: <boolean> allowToggle,
        };

        columnIterator.push(DealKanBanColumnSettingItem);
      }
      catch (error: any) {
        //Swallow the promise rejection
        console.log("Error:" + error);
      }
    });
    
    if (columnIterator.length>0) {
      this.kanbanObj.columns=columnIterator;
      this.ngZone.run(() => {this.kanbanObj.render();});
    } else { //Create the defaults:
      console.log("No Columns found for team " + this.teamID);
    }
  }

  //Kanban filter functionality:
  searchClick(e: KeyboardEvent): void {
    if (e.code === 'Tab' || e.code === 'Escape' || e.code === 'ShiftLeft' || (e.code === 'Backspace' && this.emptyValue)) {
        return;
    }
    const searchValue: string = (<HTMLInputElement>e.target).value;
    searchValue.length === 0 ? this.emptyValue = true : this.emptyValue = false;
    let searchQuery: Query = new Query();
    if (searchValue !== '') {
        searchQuery = new Query().search(searchValue, ['Id', 'Summary', 'description', 'first_name', 'last_name', 'company'], 'contains', true);
    }
    this.kanbanObj.query = searchQuery;
}

resetClick(): void {
    this.textBoxObj.value = '';
    this.reset();
}

public onFocus = (): void => {
    if (this.textBoxObj.value === null || this.textBoxObj.value === '') {
        this.reset();
    }
}

reset(): void {
    this.kanbanObj.query = new Query();
}
}


